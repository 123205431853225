<template>
  <div class="mb-sm-4 mx-3 my-4 row">
    <div class="col-lg-7 mb-3">
      <div class="border">
        <div class="row p-4">
          <div ref="mask" class="col">
            <h5 class="h5 text-muted font-weight-bold">{{ title }}</h5>
            <p>{{ prompt }}</p>
            <div class="form-group pt-2">
              <label class="h6 font-weight-bold">Link to {{ accept }} file</label>
              <input
                v-model="form.url"
                class="form-control"
                placeholder="https://www.e4e-reports/dmi/4820475"
              />
            </div>
            <div class="mb-4 pt-2">
              <label class="h6 font-weight-bold">Or Upload File Here</label>
            </div>

            <el-upload
              ref="drag"
              drag
              :on-error="_handleError"
              :before-upload="_handleBeforeUpload"
              action="#"
              :accept="accept"
              :http-request="_handleFileSelected"
              :on-success="_handleSuccess"
              :file-list="form.dragList"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                <em>Upload {{ accept }} file</em>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="row pt-2 px-4 py-4">
          <div class="col">
            <button
              :disabled="disable"
              class="btn float-right btn-success btn-action shadow"
              @click="submitUpload"
            >
              {{ actionText }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-5">
      <div class="border bg-gray">
        <div class="row p-4">
          <div class="col">
            <h5 class="h5 text-muted">Download Import File Template</h5>
            <p>
              Use this template to structure and arrange the data before uploading it back here.
            </p>

            <p class="text-danger font-weight-bold">
              Please note that the DMI might not be able to process data arranged in any other way
              besides that specified in this template.
            </p>

            <p>
              Maximum file size: <b>{{ max }}KB</b>
            </p>
          </div>
        </div>
        <div class="row px-4 mt-5 mb-4">
          <button class="btn btn-primary btn-lg shadow">
            Download Template <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
          </button>
          <button class="btn btn-link text-muted">Read help documentation</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadComponent",
  props: {
    actionText: {
      type: String,
      default: "Proceed",
    },

    disable: {
      type: Boolean,
      default: false,
    },
    /**
     *
     */
    title: {
      type: String,
      default: "Update Data from file or link",
    },
    /**
     *
     */
    prompt: {
      type: String,
      default:
        "Done structuring the data? You can import it to the DMI by uploading the edited .xsl file or by pasting a link to it here.",
    },
    /**
     *
     */
    max: {
      type: Number,
      default: 0,
    },
    /**
     *
     */
    accept: {
      type: String,
      default: ".xlsx",
    },
    // eslint-disable-next-line vue/require-default-prop
    handleBeforeUpload: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    handleError: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    handleSuccess: {
      type: Function,
    },
  },
  data() {
    return {
      form: {
        url: null,
        dragList: [],
      },
      selectedFile: undefined,
    };
  },
  methods: {
    /**
     *
     */
    submitUpload() {
      if (!this.form.url && !this.selectedFile) {
        return this.$message({
          message: "No File/Url provided",
          type: "error",
          duration: 5 * 1000,
        });
      }
      this.$emit("submit", { file: this.selectedFile, url: this.form.url });
    },
    /**
     *
     */
    // eslint-disable-next-line no-unused-vars
    _handleError(error, file, fileList) {
      if (this.handleError) return this.handleError(error, file, fileList);
      if (error.response?.status) return; // request errors are handled automatically
      return this.$notify.error({
        title: "Error Uploading file",
        message: error,
        duration: 0,
      });
    },
    /**
     *
     */
    // eslint-disable-next-line no-unused-vars
    _handleSuccess(response, file, fileList) {
      if (this.handleSuccess) return this.handleSuccess(response, file, fileList);
    },
    /**
     *
     */
    _handleBeforeUpload(file) {
      if (this.max > 0) {
        let max_size = this.max * 1024;
        if (file.size > max_size) {
          this.$notify.closeAll();
          this.$notify.error({
            title: "Upload error",
            message: `Maximum file size ${this.max}KB exceeded`,
            duration: 0,
          });
          return false;
        }
      }
      if (this.handleBeforeUpload) return this.handleBeforeUpload(file);
    },
    /**
     *
     */
    clearFiles() {
      this.$refs["drag"].clearFiles();
    },
    // eslint-disable-next-line no-unused-vars
    async _handleFileSelected({ file }) {
      this.selectedFile = file;
    },
  },
};
</script>
