<template>
  <div>
    <SubNav main-route="data" title="Upload data" />
    <div class="container-fluid pb-1" style="top: 65px">
      <FileUpload
        ref="fileUpload"
        :max="maxSize"
        :action-text="'Preview Data'"
        :disable="disableBtn"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import SubNav from "../../components/NavigationSubNav.vue";
import UploadResource from "../../api/fileResource";
import FileUpload from "../../components/FileUpload.vue";
import Mixin from "./mixin";
import XLSX from "xlsx";
import { PREVIEW_STAGE } from "../../store/stages";

const uploadResource = new UploadResource();

export default {
  name: "CreateData",
  components: {
    SubNav,
    FileUpload,
  },
  mixins: [Mixin],
  data() {
    return {
      maxSize: 1470,
      format: ".xlsx",
      disableBtn: false,
    };
  },
  methods: {
    /**
     * Form
     * @typedef {{file: File, url: String}} Form
     */

    /**
     * @param {Form} form
     */
    async handleSubmit(form) {
      const { url, file } = form;
      if (file) {
        // eslint-disable-next-line no-unused-vars
        const loader = this.createLoader(this.$refs.fileUpload.$refs.mask, null, null);

        this.disableBtn = true;

        this.$store.commit("data/SET_FILE", file);
        const cells = await this.readExcelFile(file);
        this.$store.commit("data/SET_EDITTING", cells);
        this.$store.commit("data/SET_STAGE", PREVIEW_STAGE);

        this.$router
          .push("/data/preview")
          .then(() => {})
          .catch((e) => console.error(e))
          .finally(() => {
            loader.close();
            this.disableBtn = false;
          });
      }
      if (url) {
        this.$store.commit("data/SET_FILE_URL", url);
      }
    },

    /**
     * @param {String} fileURL
     */
    async handleURLsubmitted(fileURL) {
      this.$progress.start();
      uploadResource
        .store({ file: fileURL })
        .then((response) => {
          console.log(response);
          this.$progress.start();
        })
        .catch((error) => {
          console.log(error);
          this.$progress.start();
        });
    },

    /**
     * @param {File} file
     */
    readExcelFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = e.target.result;
            const workbook = XLSX.read(data, {
              type: "array",
            });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const results = XLSX.utils.sheet_to_json(worksheet);
            const cells = results.map((cell, i) => {
              cell.$id = i;
              return cell;
            });
            return resolve(cells);
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {},
};
</script>

<style lang="scss" scoped></style>
